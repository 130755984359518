"use client";

import { CenteredDisplay } from "@/components/centered-display";
import type { RootLayoutProps } from "@/types";
import { type PublicSession, useSession } from "@semperfi-it/auth-next-js";
import { AlertCircle, Loader2 } from "lucide-react";
import { createContext, useContext, useEffect, useState } from "react";

type AppContext = {
    session: PublicSession | null;
    operator: AppOperator | null;
};

export const AppContext = createContext<AppContext>({
    session: null,
    operator: null,
});

export const useAppContext = () => useContext(AppContext) as AppContext;

export type AppOperator = {
    keycloakId: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: Date;
    isManagementOrganizationOperator?: boolean;
    isMunicipalOperator?: boolean;
    managementOrganization?: {
        id: string;
        name: string;
    };
    municipal?: {
        invgateId: string;
        name: string;
        uri: string;
    };
};

export const AppContextProvider = ({ children }: RootLayoutProps) => {
    const { session: userSession, error, loading } = useSession();

    const [operator, setOperator] = useState<AppOperator | null>(null);
    const [session, setSession] = useState<PublicSession | null>(userSession);
    const [loadingState, setLoadingState] = useState<boolean>(true);

    useEffect(() => {
        setSession(userSession);
        setLoadingState(true);

        const fetchOperator = async () => {
            if (userSession?.user?.id) {
                try {
                    const response = await fetch(`/api/operators/${userSession.user.id}`);
                    if (!response.ok) {
                        throw new Error("Failed to fetch operator");
                    }
                    const data = await response.json();

                    if (data?.managementOrganizationOperators?.length > 0) {
                        const managementOperator = data.managementOrganizationOperators[0];
                        setOperator({
                            ...data,
                            ...managementOperator,
                            isManagementOrganizationOperator: true,
                            managementOrganization: managementOperator.managementOrganization,
                        });
                    } else if (data?.municipalOperators?.length > 0) {
                        const municipalOperator = data.municipalOperators[0];
                        setOperator({
                            ...data,
                            ...municipalOperator,
                            isMunicipalOperator: true,
                            municipal: municipalOperator.municipal,
                        });
                    } else {
                        setOperator(null);
                    }
                } catch (error) {
                    console.error(error);
                    setOperator(null);
                }
            } else {
                setOperator(null);
            }

            setLoadingState(false);
        };

        fetchOperator();
    }, [userSession]);

    if (loading) {
        return <CenteredDisplay icon={Loader2} message="Loading..." />;
    }

    if (!session || error) {
        return (
            <CenteredDisplay
                icon={AlertCircle}
                message={error || "Unauthorized access"}
            />
        );
    }

    if (loadingState) {
        return (
            <CenteredDisplay icon={Loader2} message="Loading operator data..." />
        );
    }

    if (!operator) {
        return <CenteredDisplay icon={AlertCircle} message="No operator found" />;
    }

    return (
        <AppContext.Provider value={{ session, operator }}>
            {children}
        </AppContext.Provider>
    );
};
