import { AlertCircle, Loader2 } from "lucide-react";

interface CenteredDisplayProps {
    icon: React.ElementType;
    message: string;
}

export const CenteredDisplay = ({
    icon: Icon,
    message,
}: CenteredDisplayProps) => (
    <div className="h-[calc(100vh-theme(spacing.16))] w-full flex flex-col items-center justify-center gap-4">
        <Icon className={`h-8 w-8 ${Icon === Loader2 ? "animate-spin" : ""}`} />
        <p className="text-lg">{message}</p>
    </div>
);

export const LoadingDisplay = () => (
    <CenteredDisplay icon={Loader2} message="Loading..." />
);

export const ErrorDisplay = ({ message }: { message: string }) => (
    <CenteredDisplay icon={AlertCircle} message={message} />
);
