import { useCallback, useEffect, useState } from 'react';

type Language = 'en' | 'nl';

export const useLanguage = () => {
    const [language, setLanguage] = useState<Language>('en');

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') as Language;
        if (savedLanguage) {
            setLanguage(savedLanguage);
        } else {
            const browserLanguage = navigator.language.split('-')[0] as Language;
            setLanguage(browserLanguage === 'nl' ? 'nl' : 'en');
        }
    }, []);

    const setNewLanguage = useCallback((newLanguage: Language) => {
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
        window.location.reload();
    }, []);

    return { language, setNewLanguage };
}; 