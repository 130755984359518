"use client";

import { useLanguage } from '@/hooks/useLanguage';
import enMessages from '@/messages/en.json';
import nlMessages from '@/messages/nl.json';
import { createContext, ReactNode, useContext } from 'react';

type Variables = Record<string, string>;

const TranslationsContext = createContext<{
    t: (key: string, variables?: Variables) => string;
    language: string;
    setNewLanguage: (lang: 'en' | 'nl') => void;
} | null>(null);

export function TranslationsProvider({ children }: { children: ReactNode }) {
    const { language, setNewLanguage } = useLanguage();
    const messages = language === 'nl' ? nlMessages : enMessages;

    const t = (key: string, variables?: Variables) => {
        const keys = key.split('.');

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let value: any = messages;

        for (const k of keys) {
            value = value?.[k];
        }

        if (variables && typeof value === 'string') {
            return Object.entries(variables).reduce(
                (acc, [key, val]) => acc.replace(`{${key}}`, val),
                value
            );
        }

        return value || key;
    };

    return (
        <TranslationsContext.Provider value={{ t, language, setNewLanguage }}>
            {children}
        </TranslationsContext.Provider>
    );
}

export const useTranslations = () => {
    const context = useContext(TranslationsContext);
    if (!context) {
        throw new Error('useTranslations must be used within a TranslationsProvider');
    }
    return context;
}; 